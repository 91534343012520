@import "mixins.scss";
@import "variables.scss";

/* Smartphones portrait/default */
.mobile-share-container {
  display: none;
  font-size: 0px;
  height: 100%;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  
  .share-overlay {
    background: #000;
    height: 100%;
    opacity: 0.8;
    position: relative;
    top: 0;
    width: 100%;
  }
  
  .mobile-share-list-container {
    display: block;
    left: 50%;
    margin: -133px -125px 0;
    overflow: visible;
    position: absolute;
    top: 40%;
    width: 255px;
    z-index: 99;
    
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      padding: 0;
      border: none;

      .fa {
        color: white;
        font-size: 20px;
      }
    }

    .mobile-share-list {
      display: block;
      height: 100%;
      margin: 0;
      position: relative;
      width: 100%;

      li {
        display: inline-block;
        font-size: 61px;
        height: 122px;
        line-height: 122px;
        list-style-type: none;
        margin-bottom: 3px;
        margin-right: 3px;
        overflow: hidden;
        position: relative; 
        text-align: center;
        width: 122px;

        &.li-share-btn {
          background-color: $linkedinColor;
        }

        &.fb-share-btn {
          background-color: $facebookColor;
        }

        &.tw-share-btn {
          background-color: $twitterColor;
        }

        &.gplus-share-btn {
          background-color: $googleColor;
        }

        &.sms-share-btn,
        &.whatsapp-share-btn {
          background-color: $textColor;
        }

        &.whatsapp-share-btn {
          .fa-stack {
            font-size: .5em;
            margin-top: -17.5px;
            
            .fa-comment-o {
              @include stretch(1, 1.3);
            }
          }

        }

        &.pinterest-share-btn {
          background-color: white;

          .fa {
            color: $pinterestColor;
          }
        }

        &.email-share-btn {
          background-color: $emailColor;
        }

        a {
          color: white;
        }
      }
    }

    div.share-msg {
      color: white;
      font-size: 16px;
      margin: 5px 0 5px 0;
      text-align: center;
    }
  } 
}

.landscape .mobile-share-list-container {
  left: 40%;
  top: 50%;
  width: 386px;
}

@media
screen and (min-width: 700px){
  .landscape .mobile-share-list-container {
    left: 45%;
    top: 50%;
    width: 380px;
  }
}

.no_scroll {
  position: fixed;
  width: 100%;

  .scroll_to_top {
    visibility: hidden;
  }
}